<template>
  <v-app-bar
    id="app-bar"
    app
    color="light-grey"
    flat
    fixed
    height="77.5"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
    >
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list
        :tile="false"
        nav
      >
        <div>
          <v-list-item
            v-for="(item, i) in userMenu"
            :key="i"
            @click="menuActionClick(item.action)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'DashboardCoreAppBar',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      userMenu: [
        {
          text: 'Profile',
          action: 'profile',
          icon: 'mdi-account',
        },
        {
          text: 'Logout',
          action: 'logout',
          icon: 'mdi-logout',
        },
      ],
    }),
    computed: {
      ...mapState(['drawer']),
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      menuActionClick (action) {
        if (action === 'logout') {
          this.$store.dispatch('authentication/doLogout')
            .then(() => {
              this.$router.replace(this.$route.query.redirect || '/auth/login')
            })
            .catch(() => {
              this.$router.replace(this.$route.query.redirect || '/auth/login')
            })
        }
        if (action === 'profile') {
          this.$router.replace(this.$route.query.redirect || '/pages/profile')
        }
      },
    },
  }
</script>
<style scoped>
.grad {
  background-image: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);
}
</style>
